import React from "react";
import itemListStyle from "./item-list.scss";
import { getListItemLink } from "./item-list.utils";

export interface ListItem {
  cta: string;
  subtitle: string;
  description: string;
  caption: string;
}

interface ItemListProps {
  listItems: ListItem[];
}

export const ItemListComponent: React.FunctionComponent<ItemListProps> = ({
  listItems,
}) => {
  return (
    <div className="item-list">
      <style jsx>{itemListStyle}</style>
      {listItems.map((item, index) => {
        const ctaElement = getListItemLink(item);

        return (
          <div className="grid" key={index}>
            <div className="content">
              <div className="item cta">{ctaElement}</div>
              <div className="item subtitle">{item.subtitle}</div>
            </div>
            <div className="item caption">{item.caption}</div>
          </div>
        );
      })}
    </div>
  );
};
