import { isDefined, useLoadingStatus } from "@wisr/common";
import { SkeletonLoader } from "@wisr/react-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ColumnGraphChartComponent } from "../../../charts/column-graph/column-graph-chart.component";
import { analyticsLoadWidgetEvent } from "../../../shared/analytics/analytics.utils";
import { stringToDate } from "../../../shared/date.utils";
import { ColumnData } from "../../../types/graph";
import { useGetUser } from "../../../user/user.hooks";
import { ErrorWidgetComponent } from "../../shared/error/error-widget.component";
import { Title } from "../../shared/title/title.component";
import { WidgetComponent } from "../../widget.component";
import { useGetLoanById } from "../personal-loan/personal-loan-borrowing-widget.hooks";
import {
  brandYellowColour,
  HISTORY_MONTH_LIMIT,
  labelColour,
} from "./history-borrowing-widget";
import { historyBorrowingGetAction } from "./history-borrowing-widget.actions";
import { GETLOANHISTORY_BORROWING_LOADING } from "./history-borrowing-widget.epic";
import { useGetHistory } from "./history-borrowing-widget.hooks";
import { repaymentHistoryMonthlyData } from "./history-borrowing-widget.utils";
import historyStyle from "./history.scss";

export const HistoryBorrowingWidget: React.FC = () => {
  const [columnData, setColumnData] = useState([] as ColumnData[]);
  const [columnCategories, setColumnCategories] = useState([] as string[]);
  const [loading, loadingError] = useLoadingStatus(
    GETLOANHISTORY_BORROWING_LOADING
  );

  const dispatch = useDispatch();
  const user = useGetUser();
  const loanData = useGetLoanById(user?.loanId);
  const historyData = useGetHistory(user?.loanId);

  React.useEffect(() => {
    analyticsLoadWidgetEvent("PL repayment graph");
  }, []);

  React.useEffect(() => {
    if (loanData?.loanId && !historyData) {
      dispatch(historyBorrowingGetAction(loanData.loanId, HISTORY_MONTH_LIMIT));
    }
  }, [loanData, dispatch, historyData]);

  React.useEffect(() => {
    if (isDefined(historyData)) {
      // Repayments will be in descending order
      const loansHistory = [...historyData]; // deep copy
      const loanRepayments =
        repaymentHistoryMonthlyData(loansHistory).reverse();
      const chartCategories = loanRepayments.map((item) =>
        stringToDate(item.effectiveDate, "month")
      );

      const paymentData = loanRepayments.map(
        (item) => item.creditAmount - item.debitAmount
      );
      const chartData: ColumnData[] = [
        {
          name: "Debits",
          data: paymentData,
          colour: brandYellowColour,
          legendIndex: 0,
        },
      ];
      setColumnCategories(chartCategories);
      setColumnData(chartData);
    }
  }, [historyData]);

  if (loadingError) {
    return (
      <WidgetComponent
        widgetName="borrow-repayments-history-error"
        widgetType="borrow"
      >
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }

  return (
    <WidgetComponent widgetName="repayments-history" widgetType="borrow">
      <style jsx>{historyStyle}</style>
      {loading || !isDefined(loanData) ? (
        <div className="loading-wrapper">
          <SkeletonLoader lines={1} />
          <SkeletonLoader lines={4} />
        </div>
      ) : (
        <>
          <Title>
            <h2>Loan repayment history</h2>
          </Title>
          <p>
            Track your repayments over the past{" "}
            <span className="months-mobile">6</span>
            <span className="months-desktop">12</span> months
          </p>
          <div className="widget-data" id="repayments-history-widget">
            <div className="widget-legend"></div>
            <div className="widget-graph">
              {historyData && (
                <ColumnGraphChartComponent
                  categories={columnCategories}
                  columns={columnData}
                  labelColour={labelColour}
                />
              )}
            </div>
          </div>
        </>
      )}
    </WidgetComponent>
  );
};
