import React from "react";
import { DownloadableDocument } from "../../borrowing/documents/documents-borrowing";
import { ButtonDownload } from "./button/button-download.component";
import downloadStyle from "./item-download.scss";

interface DownloadItemProp {
  itemIndex: string;
  item: DownloadableDocument;
}

export const ItemDownloadComponent: React.FunctionComponent<
  DownloadItemProp
> = ({ item, itemIndex }) => {
  return (
    <div className="grid" id={`document-${itemIndex}`}>
      <style jsx>{downloadStyle}</style>
      <div className="item item-details">
        <span className="title">{item.name}</span>
      </div>
      <div className={`item download`}>
        <ButtonDownload apiLink={item.downloadUrl} filename={item.name} />
      </div>
    </div>
  );
};
