import { toCurrency } from "@wisr/common";
import dayjs from "dayjs";
import { stringToDate } from "../../../shared/date.utils";
import { ListItem } from "../../shared/list/item-list.component";
import { HistoryPayment } from "../history/history-borrowing-widget";
import { RepaymentsListProps } from "./list/list-repayments-borrowing-widget.component";
import { GetRepaymentsReponse } from "./repayments-borrowing-widget";

export const convertPaymentsToListItem = (
  payments: GetRepaymentsReponse
): ListItem => {
  return {
    cta: "Scheduled repayment",
    subtitle: stringToDate(payments.repaymentDate),
    caption: toCurrency(payments.repaymentAmount, true),
    description: payments.description || "",
  };
};

export const convertHistoryToListItem = (history: HistoryPayment): ListItem => {
  return {
    cta: history.transactionDescription,
    caption: history.creditAmount
      ? `+ ${toCurrency(+history.creditAmount)}`
      : `- ${toCurrency(+history.debitAmount)}`,
    description: history.description,
    subtitle: "",
  };
};

export const getDailyHistoryRepayments = (repayments: HistoryPayment[]) => {
  // Group up all the arrays by repayment day
  return repayments.reduce((storage, item) => {
    const objKey: string = dayjs(item.effectiveDate)
      .format("DD-MM-YYYY")
      .toString();
    if (
      storage[objKey] &&
      dayjs(storage[objKey][0].effectiveDate)
        .format("DD-MM-YYYY")
        .toString() === objKey
    ) {
      storage[objKey].push(item);
    } else {
      storage[objKey] = [item];
    }
    return storage;
  }, {} as { [key: string]: HistoryPayment[] });
};

/* This function is used so we can implement it with the pagination
 * to limit the amount of individual items shown in each top level array,
 * instead of the grouped days. So, each array will have max `limit` items in it
 * regardless of how many arrays are inside.
 * Converts historyPayment data into the list format we want in the widget
 */
export const limitHistoryItems = (
  historyList: HistoryPayment[],
  limit: number
) => {
  // A 2D array is needed for this to sort out nesting
  const historyLimitList = [] as RepaymentsListProps[][];
  let i = 0;
  while (historyList.length) {
    const repayOrdered = getDailyHistoryRepayments(
      // Splice updates the original array, so we can just keep reusing it
      historyList.splice(0, limit)
    );
    Object.keys(repayOrdered).forEach((payment) => {
      const title = payment;
      const list = repayOrdered[payment].map((pay: HistoryPayment) => {
        return convertHistoryToListItem(pay);
      });
      if (typeof historyLimitList[i] === "undefined") {
        // If array item doesnt exist create an empty array
        historyLimitList[i] = [];
      }
      historyLimitList[i].push({
        title,
        list,
      });
    });
    i++;
  }
  return historyLimitList;
};

// Split the payment items into `limit` number
export const limitPaymentsItems = (
  payments: GetRepaymentsReponse[],
  limit: number
) => {
  // A 2D array is needed for this to sort out nesting
  const paymentsLimitList = [] as ListItem[][];
  let i = 0;
  while (payments.length) {
    const limitedPayments = payments.splice(0, limit);

    const listItems = limitedPayments.map((payment) =>
      convertPaymentsToListItem(payment)
    );
    paymentsLimitList[i] = listItems;
    i++;
  }
  return paymentsLimitList;
};
