import { LOANS_API } from "../../../shared/url.consts";
import {
  DownloadableDocument,
  GetDocumentsResponse,
} from "./documents-borrowing";

const docDisplayNames: { [key: string]: string } = {
  GeneratedLoanContractFile: "Loan Contract",
  GeneratedLoanDebitFile: "Direct Debit Agreement",
};

export const mapToDownloadableDocument = (
  doc: GetDocumentsResponse,
  loanId: number
): DownloadableDocument => {
  const downloadUrl = `${LOANS_API}/loans/${loanId}/contract/documents/${doc.documentAssetId}`;
  const hasDisplayName = Object.prototype.hasOwnProperty.call(
    docDisplayNames,
    doc.type
  );
  const name = hasDisplayName ? docDisplayNames[doc.type] : doc.type;

  return {
    documentAssetId: doc.documentAssetId,
    added: doc.added,
    type: doc.type,
    name,
    downloadUrl,
  };
};
