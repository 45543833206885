import { classFormat } from "@wisr/common";
import { Action } from "@wisr/react-ui";
import React, { useState } from "react";
import axios from "../../../../shared/axios.utils";
import { downloadFile } from "../download.utils";
import downloadStyle from "./button-download.scss";

interface Props {
  apiLink: string;
  filename: string;
  defaultState?: string;
  customText?: boolean;
  onClick?: () => void;
}

type DownloadState = "Download" | "Downloading" | "Downloaded" | "Error";

export const ButtonDownload: React.FunctionComponent<Props> = ({
  apiLink,
  filename,
  defaultState,
  customText = false,
  children,
  onClick,
}) => {
  const [downloadState, setDownloadState] = useState(
    defaultState || ("Download" as DownloadState)
  );

  const downloadApi = (link: string, name: string) => {
    setDownloadState("Downloading");

    axios
      .get(link, { responseType: "blob" })
      .then((res) => {
        return downloadFile(link, name, res.data).then(() => {
          setDownloadState("Downloaded");
        });
      })
      .catch((error) => {
        console.error(error);
        setDownloadState("Error");
        setTimeout(() => {
          setDownloadState("Download");
        }, 4000);
      });
  };

  return (
    <div className={`item download ${classFormat(downloadState)}`}>
      <style jsx>{downloadStyle}</style>

      <Action size="small">
        <button
          type="button"
          onClick={() => {
            downloadApi(apiLink, filename);
            if (onClick) {
              onClick();
            }
          }}
        >
          {customText ? (
            children
          ) : (
            <>
              <span className={"icon"} /> {downloadState}
            </>
          )}
        </button>
      </Action>
    </div>
  );
};
