import React from "react";
import { stringToDate } from "../../../../shared/date.utils";
import {
  ItemListComponent,
  ListItem,
} from "../../../shared/list/item-list.component";
import listStyle from "./repayments-list.scss";

export interface RepaymentsListProps {
  title: string;
  list: ListItem[];
}
export const ListRepaymentsBorrowingWidgetComponent: React.FunctionComponent<{
  repaymentsList: RepaymentsListProps;
}> = ({ repaymentsList }) => {
  const listTitleDate = stringToDate(
    repaymentsList.title,
    "full-date",
    "DD-MM-YYYY"
  );
  return (
    <div className={`repayment-date ${listTitleDate ? "has-title" : ""}`}>
      <style jsx>{listStyle}</style>
      {listTitleDate && <h3>{listTitleDate}</h3>}
      <ItemListComponent listItems={repaymentsList.list} />
    </div>
  );
};
