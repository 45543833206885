import { isBrowser } from "@wisr/common";
import React from "react";
import { useDispatch } from "react-redux";
import { Loans } from "../../../loans/loans.component";
import { analyticsViewPageEvent } from "../../../shared/analytics/analytics.utils";
import { useQueryString } from "../../../shared/gatsby.utils";
import { GatsbyPageProps } from "../../../types/gatsby";
import { userGetSuccessAction } from "../../../user/user.actions";
import { useGetUser } from "../../../user/user.hooks";
import { DocumentsBorrowingWidget } from "../../../widgets/borrowing/documents/documents-borrowing-widget";
import { HistoryBorrowingWidget } from "../../../widgets/borrowing/history/history-borrowing-widget.component";
import { RepaymentsBorrowingWidget } from "../../../widgets/borrowing/repayments/repayments-borrowing-widget.component";
import { WidgetWrapper } from "../../../widgets/widget.component";
import { ManageLoanWidget } from "../../../widgets/borrowing/manage-loan/manage-loan-widget";
import { useGetLoanById } from "../../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import { isDischargedLoan } from "../../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.utils";
import { useGetRateOfferState } from "../../../redux/rate-offer/rate-offer.hooks";
import { useGetEstimateEligible } from "../../../widgets/credit/score/score-credit-widget.hooks";
import { SecondLoanEstimateWidget } from "../../../widgets/borrowing/rate-estimate/second-loan-estimate/second-loan-estimate-widget";

const LoansPage = (props: GatsbyPageProps) => {
  const { loanId } = useQueryString(props.location);
  const dispatch = useDispatch();
  const user = useGetUser();
  const loan = useGetLoanById(user.loanId);
  const isDischarged = isDischargedLoan(loan);
  const hasApplications = user.applicationCount > 0;
  const isEstimateEligible = useGetEstimateEligible();
  const hasRateOffers = useGetRateOfferState().rateOffers.length > 0;

  React.useEffect(() => {
    if (loanId && Number(loanId) !== user.loanId) {
      dispatch(
        userGetSuccessAction({
          ...user,
          loanId: +loanId,
          toStorageString: /* istanbul ignore next - Not used */ () => "",
        })
      );
    }
  }, [loanId, dispatch, user]);

  if (isBrowser()) {
    analyticsViewPageEvent("Borrowing");
  } else {
    return null;
  }

  return (
    <WidgetWrapper>
      <div className="prefix">
        <Loans />
        <HistoryBorrowingWidget />
      </div>
      <div className="suffix">
        {!isDischarged && <ManageLoanWidget />}
        <DocumentsBorrowingWidget />
        {isEstimateEligible && !hasRateOffers && !hasApplications && (
          <SecondLoanEstimateWidget widgetLocation="Dashboard loans" />
        )}
        <RepaymentsBorrowingWidget />
      </div>
    </WidgetWrapper>
  );
};

export default LoansPage;
