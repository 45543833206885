import { toCurrency } from "@wisr/common";
import { StatusCallout } from "@wisr/react-ui";
import React from "react";
import {
  analyticsClickEvent,
  analyticsCustomEvent,
} from "../../shared/analytics/analytics.utils";
import { ContactLink } from "../../shared/contact-link/contact-link";
import { CreditUserProfile } from "../../user/credit/credit-user";
import style from "./arrears-callout.scss";
import { PersonalLoan } from "../../types/personal-loan";
import { trimLoanStatus } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.utils";

interface ArrearsCalloutProps {
  openModal: (open: boolean) => void;
  user?: CreditUserProfile;
  loan: PersonalLoan;
}

export const ArrearsCallout: React.FC<ArrearsCalloutProps> = ({
  openModal,
  user,
  loan,
}) => {
  React.useEffect(() => {
    analyticsCustomEvent("viewedArrearsCallout", {
      "Page name": "Loan details",
      "Loan status": trimLoanStatus(loan.status),
    });
  }, [loan.status]);

  const arrearsVal = loan.totalArrearsAmount
    ? toCurrency(loan.totalArrearsAmount)
    : "";

  return (
    <div className="status">
      <style jsx>{style}</style>
      <StatusCallout
        type="error"
        heading={`Your account is ${arrearsVal} overdue`}
        theme="outline"
        message={
          <>
            <p>
              Please pay to avoid extra fees, interest and impact to your credit
              score.
            </p>
            <p>
              We&apos;re here to help,{" "}
              <ContactLink
                location="Arrears modal"
                loanId={loan.loanId}
                page="Loan details"
                user={user}
              >
                contact us
              </ContactLink>{" "}
              if you&apos;re unable to make a payment.
            </p>
            <button
              type="button"
              className="button-link"
              onClick={() => {
                openModal(true);
                analyticsClickEvent("Make extra loan repayment", {
                  "Button location": "Arrears modal",
                });
              }}
            >
              Make a payment
            </button>
          </>
        }
      />
    </div>
  );
};
