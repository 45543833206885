import { toCurrency } from "@wisr/common";
import React, { useState } from "react";
import { ArrearsCallout } from "../../../loans/arrears/arrears-callout";
import { LoanDetails } from "../../../loans/details/loan-details";
import { LoanProps } from "../../../loans/loans";
import { stringToDate } from "../../../shared/date.utils";
import {
  HighlightComponent,
  HighlightItem,
} from "../../shared/highlight/highlight.component";
import { WidgetComponent } from "../../widget.component";
import { ExtraRepaymentModal } from "../manage-loan/extra-repayment-modal";
import loanStyle from "./personal-loan.scss";
import { shouldTreatAsInArrears } from "./personal-loan-borrowing-widget.utils";
import { PersonalLoan } from "../../../types/personal-loan";

export const PersonalLoanBorrowingWidget: React.FC<LoanProps> = ({
  loan,
  customer,
}) => {
  const [openExtraRepaymentModal, setOpenExtraRepaymentModal] = useState(false);

  const loanBalance = loan.currentBalance?.toString()
    ? toCurrency(loan.currentBalance, true)
    : "";
  const loanRepayment = loan.repaymentAmount?.toString()
    ? toCurrency(loan.repaymentAmount, true)
    : "";

  const showArrearsCallout = shouldTreatAsInArrears(loan);

  return (
    <WidgetComponent widgetName="personal-loan" widgetType="borrow">
      <style jsx>{loanStyle}</style>
      <ExtraRepaymentModal
        loan={loan}
        extraRepaymentModal={openExtraRepaymentModal}
        setOpenExtraRepaymentModal={setOpenExtraRepaymentModal}
      />

      <div className="widget-title">
        <h1>Loan ID {loan.loanId}</h1>
      </div>
      <section className="widget-data">
        <div className="loan-main">
          {showArrearsCallout && (
            <ArrearsCallout
              openModal={setOpenExtraRepaymentModal}
              user={customer}
              loan={loan}
            />
          )}
          <div className="grid">
            <HighlightComponent
              list={getLoanInfo(loanBalance, loanRepayment, loan)}
            />
          </div>
        </div>
        {<LoanDetails loan={loan} />}
      </section>
    </WidgetComponent>
  );
};

const getLoanInfo = (
  loanBalance: string,
  loanRepayment: string,
  loan: PersonalLoan
): HighlightItem[] => [
  {
    description: "Next debit amount",
    title: loanRepayment,
    type: "main",
    id: "next-debit-amount",
  },
  {
    description: "Next debit date",
    title: (
      <>
        <span className="desktop">
          {loan.nextRepaymentDate
            ? stringToDate(loan.nextRepaymentDate, "full")
            : ""}
        </span>
        <span className="mobile">
          {loan.nextRepaymentDate
            ? stringToDate(loan.nextRepaymentDate, "short")
            : ""}
        </span>
      </>
    ),
    type: "main",
    id: "next-debit-date",
  },
  {
    description: "Loan balance",
    title: loanBalance,
    type: "small",
    id: "loan-balance",
    message: [
      "Why hasn’t my loan balance been updated?",
      "It may take a few days for your remaining balance to update after you've made a repayment. But don't worry, you won't be charged any additional interest.",
    ],
  },
  {
    description: "Debit Frequency",
    title: loan.repaymentFrequency || "",
    type: "small",
    id: "debit-frequency",
  },
];
