import { useLoadingStatus } from "@wisr/common";
import { Pagination, SkeletonLoader } from "@wisr/react-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { analyticsLoadWidgetEvent } from "../../../shared/analytics/analytics.utils";
import { useGetUser } from "../../../user/user.hooks";
import { ErrorWidgetComponent } from "../../shared/error/error-widget.component";
import { Title } from "../../shared/title/title.component";
import { WidgetComponent } from "../../widget.component";
import { historyBorrowingGetAction } from "../history/history-borrowing-widget.actions";
import { useGetHistory } from "../history/history-borrowing-widget.hooks";
import { useGetLoanById } from "../personal-loan/personal-loan-borrowing-widget.hooks";
import {
  ListRepaymentsBorrowingWidgetComponent,
  RepaymentsListProps,
} from "./list/list-repayments-borrowing-widget.component";
import { limitHistoryItems } from "./repayments-borrowing-widget.utils";
import { GETLOAN_BORROWING_LOADING } from "../personal-loan/personal-loan-borrowing-widget.epic";
import repaymentsBorrowingWidgetStyle from "./repayments-borrowing-widget.scss";

export const RepaymentsBorrowingWidget: React.FC = () => {
  const [repaymentsList, setRepaymentsList] = useState<RepaymentsListProps[][]>(
    []
  );
  const [, loadingError] = useLoadingStatus(GETLOAN_BORROWING_LOADING);

  const dispatch = useDispatch();
  const user = useGetUser();
  const loanData = useGetLoanById(user.loanId);
  const historyData = useGetHistory(user.loanId);

  React.useEffect(() => {
    analyticsLoadWidgetEvent("PL repayment history");
  }, []);

  React.useEffect(() => {
    if (loanData?.loanId && !historyData) {
      // Just need the last 12 months
      dispatch(historyBorrowingGetAction(loanData.loanId, 12));
    }
  }, [loanData, dispatch, historyData]);

  React.useEffect(() => {
    if (historyData) {
      const tempHistoryList = [...historyData]; // Deep clone so we dont mess with the data
      const repayList = limitHistoryItems(tempHistoryList, 8); // max 8 items per object
      setRepaymentsList(repayList);
    }
  }, [historyData]);

  const getRepayments = () => {
    return repaymentsList.map((repayment, index) => (
      <div key={index}>
        {repayment.map((list) => (
          <ListRepaymentsBorrowingWidgetComponent
            key={list.title}
            repaymentsList={list}
          />
        ))}
      </div>
    ));
  };

  if (loadingError) {
    return (
      <WidgetComponent widgetName="borrow-repayments-error" widgetType="borrow">
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }
  if (loanData) {
    return (
      <WidgetComponent widgetName="repayments" widgetType="borrow">
        <style jsx>{repaymentsBorrowingWidgetStyle}</style>
        <Title>
          <h2>Loan repayments</h2>
        </Title>
        <div className="pagination-wrapper">
          <Pagination itemsPerPage={1}>{getRepayments()}</Pagination>
        </div>
      </WidgetComponent>
    );
  }

  return (
    <div className="loading-wrapper">
      <SkeletonLoader lines={6} />
    </div>
  );
};
