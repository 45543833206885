import { isDefined, useLoadingStatus } from "@wisr/common";
import { SkeletonLoader } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";
import { useGetUser } from "../../../user/user.hooks";
import { ItemDownloadComponent } from "../../shared/download/item-download.component";
import { ErrorWidgetComponent } from "../../shared/error/error-widget.component";
import { Title } from "../../shared/title/title.component";
import { WidgetComponent } from "../../widget.component";
import { useGetLoanById } from "../personal-loan/personal-loan-borrowing-widget.hooks";
import { DownloadableDocument } from "./documents-borrowing";
import { mapToDownloadableDocument } from "./documents-borrowing.utils";
import { documentsBorrowingGetAction } from "./documents-borrowing-widget.actions";
import { GETDOCUMENTS_BORROWING_LOADING } from "./documents-borrowing-widget.epic";
import { useGetDocuments } from "./documents-borrowing-widget.hooks";

import docStyle from "./documents.scss";
import { LoanStatements } from "../../../loans/statements/loan-statements";
import { useGetCreditUser } from "../../../user/credit/credit-user.hooks";

export const DocumentsBorrowingWidget: React.FC = () => {
  const [loading, loadingError] = useLoadingStatus(
    GETDOCUMENTS_BORROWING_LOADING
  );

  const dispatch = useDispatch();
  const user = useGetUser();
  const customer = useGetCreditUser();
  const loanData = useGetLoanById(user?.loanId);

  const documents = useGetDocuments(user?.loanId);

  let downloadableDocs: DownloadableDocument[] = [];

  if (documents && loanData?.loanId) {
    downloadableDocs = documents.map(
      (doc) => mapToDownloadableDocument(doc, loanData.loanId as number) // LoandId will be not null at this stage
    );
  }

  React.useEffect(() => {
    if (loanData?.loanId && !documents) {
      dispatch(documentsBorrowingGetAction(loanData.loanId));
    }
  }, [loanData?.loanId, dispatch, documents]);

  if (loadingError) {
    return (
      <WidgetComponent widgetName="borrow-documents-error" widgetType="borrow">
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }

  return (
    <WidgetComponent widgetName="documents" widgetType="borrow">
      <style jsx>{docStyle}</style>
      {loading || !isDefined(loanData) || !isDefined(customer) ? (
        <div className="loading-wrapper">
          <SkeletonLoader lines={4} />
        </div>
      ) : (
        <>
          <Title>
            <h2>Your documents</h2>
          </Title>
          <div className="widget-data">
            <ul>
              <li>
                <LoanStatements
                  user={user}
                  customer={customer}
                  loan={loanData}
                />
              </li>
              {downloadableDocs.map((doc) => (
                <li key={doc.documentAssetId}>
                  <ItemDownloadComponent
                    item={doc}
                    itemIndex={doc.documentAssetId.toString()}
                  />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </WidgetComponent>
  );
};
