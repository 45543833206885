import React from "react";
import { Button, Modal, StatusCallout } from "@wisr/react-ui";
import repaymentFrequencyModalStyle from "./repayment-frequency-modal.scss";
import qrCode from "../../../assets/images/wisr-app-qr-code.png";
import iosButtonImg from "../../../assets/images/ios-button.svg";
import googlePlayButtonImg from "../../../assets/images/google-play-button.svg";
import { STORE_TYPE } from "../../../shared/analytics/analytics.consts";
import { analyticsCustomEvent } from "../../../shared/analytics/analytics.utils";
import { AppStoreProviders } from "../../../types/analytics";
import { isBrowserMobile } from "../../../shared/browser/browser.utils";

interface RepaymentFrequencyModalProps {
  repaymentFrequencyModal: boolean;
  setRepaymentFrequencyModal: (arg0: boolean) => void;
}

export const RepaymentFrequencyModal: React.FC<
  RepaymentFrequencyModalProps
> = ({ repaymentFrequencyModal, setRepaymentFrequencyModal }) => {
  const isMobile = isBrowserMobile();
  const modalTitle = isMobile
    ? "Request to change repayment frequency"
    : "Download Wisr App to manage your loan";
  const calloutHeading = isMobile
    ? "Repay on pay day"
    : "Change your repayment frequency";

  const sendAppOfferClickTrackingEvent = (storeType: AppStoreProviders) => {
    analyticsCustomEvent("clickedWidgetOffer", {
      "Dashboard offer": "Change repayment frequency",
      "App Store": storeType,
      Location: "Dashboard credit scores home",
    });
  };

  return (
    <Modal
      titleText={modalTitle}
      open={repaymentFrequencyModal}
      onClose={() => setRepaymentFrequencyModal(false)}
    >
      <style jsx>{repaymentFrequencyModalStyle}</style>
      <div className="repayment-frequency-modal">
        <StatusCallout
          type="coaching"
          heading={calloutHeading}
          message="Paying on (or right after) payday can make budgeting easier and reduce the chance of missing payments."
        />
        <p>
          Download Wisr App to change your loan repayment frequency. You can opt
          for either weekly, fortnightly or monthly repayments.
        </p>
        <div className="download-links">
          <img src={qrCode} alt={`QR code`} className="qr-code" />
          <a
            href="https://apps.apple.com/au/app/wisr/id1368389391"
            target="_blank"
            rel="noopener nofollow"
            onClick={() => sendAppOfferClickTrackingEvent(STORE_TYPE.APPLE)}
            className="app-store-link"
          >
            <img src={iosButtonImg} alt={`iOS app store logo`} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.wisr.finance&hl=en_AU&gl=US"
            target="_blank"
            rel="noopener nofollow"
            onClick={() => sendAppOfferClickTrackingEvent(STORE_TYPE.GOOGLE)}
            className="app-store-link"
          >
            <img src={googlePlayButtonImg} alt={`Play store logo`} />
          </a>
        </div>
        <div className="button-wrapper">
          <Button theme="teal" fullWidth={true}>
            <button
              type="button"
              onClick={() => setRepaymentFrequencyModal(false)}
            >
              Close
            </button>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
