import React from "react";
import { ListItem } from "./item-list.component";
import { BRAND_WEBSITE } from "../../../shared/url.consts";

export const getListItemLink = (listItem: ListItem) => {
  if (
    listItem.description.includes(ONE_OFF_ADJUSTMENT_STRING) ||
    listItem.cta.includes(ONE_OFF_ADJUSTMENT_STRING)
  ) {
    return (
      <a
        href={`${BRAND_WEBSITE}/account-adjustment`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {listItem.cta}
      </a>
    );
  } else {
    return <>{listItem.cta}</>;
  }
};

export const ONE_OFF_ADJUSTMENT_STRING = "One-off account adjustment";
