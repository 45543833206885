import { isBrowser, isDefined, toCurrency } from "@wisr/common";
import HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartsMore from "highcharts/highcharts-more";
import React from "react";
import { ColumnData } from "../../types/graph";
import graphStyle from "./column-graph.scss";

interface ColumnProps {
  categories: string[];
  columns: ColumnData[];
  labelColour?: string;
}

export const ColumnGraphChartComponent: React.FunctionComponent<
  ColumnProps
> = ({ columns, categories, labelColour = "#617280" }) => {
  if (isDefined(columns)) {
    /* Use Highcharts more library even if we don't ned it
     * It's to prevent clashes for charts that do require it,
     *  but basically we will ned it for every chart now.
     *  Also, we need to check for window since More doens't play well with SSR
     */
    if (isBrowser()) {
      HighChartsMore(HighCharts);
    }

    const chartOptions: HighCharts.Options = {
      chart: {
        type: "column",
        height: 300,
        reflow: true,
        spacingLeft: 8,
      },
      xAxis: {
        categories: categories,
        lineColor: "#000",
        lineWidth: 0,
        labels: {
          autoRotation: undefined,
          useHTML: true,
          style: {
            fontSize: "12px",
            textTransform: "uppercase",
            color: labelColour,
          },
        },
      },
      yAxis: {
        min: 0,
        softMax: 1000,
        showEmpty: true,
        tickAmount: 3,
        showFirstLabel: true,
        showLastLabel: true,
        gridLineDashStyle: "Solid",
        gridLineColor: "#DFE3E8",
        lineWidth: 0,
        lineColor: "#000",
        labels: {
          enabled: true,
          style: {
            fontSize: "12px",
            color: labelColour,
            fontFamily: "Inter",
            fontWeight: "normal",
          },
          formatter: /* istanbul ignore next */ function () {
            return `${toCurrency(Number(this.value), false)}` as any;
          },
        },
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        align: "left",
        x: -16,
        verticalAlign: "top",
        y: 0,
        floating: false,
        backgroundColor: "white",
        borderColor: "#fff",
        borderWidth: 1,
        shadow: false,
        layout: "horizontal",
        symbolWidth: 16,
        symbolHeight: 16,
        symbolRadius: 16,
        itemDistance: 52,
        itemMarginBottom: 0,
        itemHoverStyle: {
          color: "#445059",
        },
        itemStyle: {
          fontSize: "12px",
          color: "#445059",
          fontFamily: "Inter",
          fontWeight: "bold",
        },

        useHTML: true,
        labelFormatter: /* istanbul ignore next */ function () {
          return `<span class="legend-label">${this.name}</span>`;
        },
      },
      tooltip: {
        enabled: false,
      },
      title: {
        text: "",
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      series: columnMapData(columns),
    };

    return (
      <div>
        <style jsx>{graphStyle}</style>
        <HighchartsReact highcharts={HighCharts} options={chartOptions} />
      </div>
    );
  }
  return <HighchartsReact />;
};

const columnMapData = (columns: ColumnData[]) => {
  return columns.map((item, index) => {
    return {
      name: item.name,
      type: "column",
      data: item.data,
      groupPadding: 0,
      legendIndex: isDefined(item.legendIndex) ? item.legendIndex : index,
      color: item.colour,
      states: {
        hover: {
          enabled: false,
        },
      },
      events: {
        legendItemClick: /* istanbul ignore next */ () => {
          return false;
        },
        mouseOver: /* istanbul ignore next */ () => {
          return false;
        },
      },
    } as Highcharts.SeriesOptionsType;
  });
};
