import React from "react";
import { Button, Modal } from "@wisr/react-ui";
import repaymentModalStyle from "./extra-repayment-modal.scss";
import qrCode from "../../../assets/images/wisr-app-qr-code.png";
import iosButtonImg from "../../../assets/images/ios-button.svg";
import googlePlayButtonImg from "../../../assets/images/google-play-button.svg";
import { STORE_TYPE } from "../../../shared/analytics/analytics.consts";
import { analyticsCustomEvent } from "../../../shared/analytics/analytics.utils";
import { AppStoreProviders } from "../../../types/analytics";
import { PersonalLoan } from "../../../types/personal-loan";

interface ExtraRepaymentModalProps {
  loan: PersonalLoan;
  extraRepaymentModal: boolean;
  setOpenExtraRepaymentModal: (open: boolean) => void;
}

export const ExtraRepaymentModal: React.FC<ExtraRepaymentModalProps> = ({
  loan,
  extraRepaymentModal,
  setOpenExtraRepaymentModal,
}) => {
  const mailTo = `mailto:contact@wisr.com.au?subject=Loan ID ${loan.loanId} Request for virtual BSB %26 Account&body=I would like to request a virtual BSB & Account for my loan, ${loan.loanId}`;

  const sendAppOfferClickTrackingEvent = (storeType: AppStoreProviders) => {
    analyticsCustomEvent("clickedWidgetOffer", {
      "Dashboard offer": "Make extra repayment",
      "App Store": storeType,
      Location: "Dashboard credit scores home",
    });
  };
  return (
    <Modal
      titleText="Choose your extra repayment option"
      open={extraRepaymentModal}
      onClose={() => setOpenExtraRepaymentModal(false)}
      selfClose
    >
      <style jsx>{repaymentModalStyle}</style>
      <div className="extra-repayment-modal">
        <h5>Option 1: Make a one-time payment</h5>
        <p>Download or login to Wisr App to make a one-time payment.</p>
        <div className="download-links">
          <img src={qrCode} alt={`QR code`} className="qr-code" />
          <a
            href="https://apps.apple.com/au/app/wisr/id1368389391"
            target="_blank"
            rel="noopener nofollow"
            onClick={() => sendAppOfferClickTrackingEvent(STORE_TYPE.APPLE)}
            className="app-store-link"
          >
            <img src={iosButtonImg} alt={`iOS app store logo`} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.wisr.finance&hl=en_AU&gl=US"
            target="_blank"
            rel="noopener nofollow"
            onClick={() => sendAppOfferClickTrackingEvent(STORE_TYPE.GOOGLE)}
            className="app-store-link"
          >
            <img src={googlePlayButtonImg} alt={`Play store logo`} />
          </a>
        </div>
        <h5>Option 2: Make recurring payments</h5>
        <p>
          To schedule additional recurring payments to your loan, please request
          a virtual BSB and Account Number that is linked to your Loan ID.
        </p>
        <div className="button-wrapper">
          <Button button="primary">
            <a href={mailTo}>Request Virtual BSB & Account</a>
          </Button>
        </div>
        <p>
          <strong>Note:</strong> One-time and recurring repayments are not
          substitutes for your regular automated Direct Debit loan repayments.{" "}
        </p>
      </div>
    </Modal>
  );
};
