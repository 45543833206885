import { useLoadingStatus } from "@wisr/common";
import { Action, Icon, SkeletonLoader } from "@wisr/react-ui";
import React, { useState } from "react";
import {
  analyticsClickEvent,
  analyticsCustomEvent,
  analyticsLoadWidgetEvent,
} from "../../../shared/analytics/analytics.utils";
import { useGetUser } from "../../../user/user.hooks";
import { ErrorWidgetComponent } from "../../shared/error/error-widget.component";
import { Title } from "../../shared/title/title.component";
import { WidgetComponent } from "../../widget.component";
import { useGetLoanById } from "../personal-loan/personal-loan-borrowing-widget.hooks";
import { ExtraRepaymentModal } from "./extra-repayment-modal";
import manageLoanStyle from "./manage-loan-widget.scss";
import { GETLOAN_BORROWING_LOADING } from "../personal-loan/personal-loan-borrowing-widget.epic";
import { RepaymentFrequencyModal } from "./repayment-frequency-modal";

export const ManageLoanWidget: React.FC = () => {
  const [, loadingError] = useLoadingStatus(GETLOAN_BORROWING_LOADING);

  const [openExtraRepaymentModal, setOpenExtraRepaymentModal] = useState(false);
  const [openRepaymentFrequencyModal, setOpenRepaymentFrequencyModal] =
    useState(false);

  const user = useGetUser();
  const loanData = useGetLoanById(user.loanId);

  React.useEffect(() => {
    analyticsLoadWidgetEvent("Manage loan");
  }, []);

  if (loadingError) {
    return (
      <WidgetComponent widgetName="borrow-repayments-error" widgetType="borrow">
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }
  if (loanData) {
    return (
      <WidgetComponent widgetName="repayments" widgetType="borrow">
        <style jsx>{manageLoanStyle}</style>
        <ExtraRepaymentModal
          loan={loanData}
          extraRepaymentModal={openExtraRepaymentModal}
          setOpenExtraRepaymentModal={setOpenExtraRepaymentModal}
        />
        <RepaymentFrequencyModal
          repaymentFrequencyModal={openRepaymentFrequencyModal}
          setRepaymentFrequencyModal={setOpenRepaymentFrequencyModal}
        />
        <Title>
          <h2>Manage your loan</h2>
        </Title>
        <div className="manage-loan-list">
          <Action size="small">
            <button
              className="list-item"
              type="button"
              onClick={() => {
                setOpenExtraRepaymentModal(true);
                analyticsClickEvent("Make extra loan repayment");
              }}
            >
              <Icon name="misc-money-outline" size="sm" legacy={false} />
              Make an extra payment
            </button>
          </Action>
          <hr />
          <Action size="small">
            <a
              className="list-item"
              href="https://eform.pandadoc.com/?eform=1146f597-5ece-4ff7-b516-2fb9b4efb315"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                analyticsCustomEvent("Opened direct debit form");
              }}
            >
              <Icon name="action-edit-outline" size="sm" legacy={false} />
              Update direct debit account
            </a>
          </Action>
          <hr />
          <Action size="small">
            <button
              className="list-item"
              type="button"
              onClick={() => {
                setOpenRepaymentFrequencyModal(true);
                analyticsClickEvent("Change repayment frequency");
              }}
            >
              <Icon name="action-edit-outline" size="sm" legacy={false} />
              Change repayment frequency
            </button>
          </Action>
        </div>
      </WidgetComponent>
    );
  }

  return (
    <div className="loading-wrapper">
      <SkeletonLoader lines={6} />
    </div>
  );
};
