import { isDefined, useLoadingStatus } from "@wisr/common";
import { SkeletonLoader } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";
import { isNotEmpty } from "../shared/common.utils";
import { creditUserGetAction } from "../user/credit/credit-user.actions";
import { useGetCreditUser } from "../user/credit/credit-user.hooks";
import { useGetUser } from "../user/user.hooks";
import { LoanStatuses } from "../widgets/borrowing/personal-loan/personal-loan-borrowing.constants";
import { loanBorrowingGetAction } from "../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.actions";
import { PersonalLoanBorrowingWidget } from "../widgets/borrowing/personal-loan/personal-loan-borrowing-widget";
import { GETLOAN_BORROWING_LOADING } from "../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.epic";
import { useGetLoanById } from "../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import { ErrorWidgetComponent } from "../widgets/shared/error/error-widget.component";
import { WidgetComponent } from "../widgets/widget.component";
import { DischargedLoan } from "./discharged/discharged-loan";
import loanStyle from "./loans.scss";

export const Loans: React.FC = () => {
  const [loading] = useLoadingStatus(GETLOAN_BORROWING_LOADING);

  const dispatch = useDispatch();
  const customer = useGetCreditUser();
  const user = useGetUser();

  const loanData = useGetLoanById(user?.loanId);

  React.useEffect(() => {
    dispatch(creditUserGetAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (!isNotEmpty(loanData)) {
      dispatch(loanBorrowingGetAction());
    }
  }, [loanData, dispatch]);

  if (loading) {
    return (
      <WidgetComponent widgetName="personal-loan" widgetType="borrow">
        <style jsx>{loanStyle}</style>
        <div className="loading-wrapper">
          <div className="loading-top-wrapper">
            <SkeletonLoader lines={4} />
          </div>
          <SkeletonLoader lines={2} />
        </div>
      </WidgetComponent>
    );
  }

  if (isDefined(loanData)) {
    if (loanData.status === LoanStatuses.DISCHARGED) {
      return <DischargedLoan loan={loanData} user={user} customer={customer} />;
    } else {
      return (
        <PersonalLoanBorrowingWidget
          loan={loanData}
          user={user}
          customer={customer}
        />
      );
    }
  }

  return (
    <WidgetComponent
      widgetName="borrow-personal-loan-error"
      widgetType="borrow"
    >
      <ErrorWidgetComponent />
    </WidgetComponent>
  );
};
